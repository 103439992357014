/* src/App.css */
body {
  font-family: Arial, sans-serif;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items:center;
  background-color: #333;
  padding: 1rem;
}

.brand-title {
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
}

.navbar ul {
  display: flex;
  list-style: none;
}

.navbar ul li {
  margin: 0 1rem;
}

.navbar ul li a {
  color: white;
  text-decoration: none;
}

.privacyPolicy {
  margin: 2rem;
}

h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

h2 {
  font-size: 1.5rem;
  margin-top: 1.5rem;
}

p, li {
  font-size: 1rem;
  line-height: 1.6;
}

ul {
  margin-left: 1.5rem;
}
